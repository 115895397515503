/* eslint-disable */
/* tslint:disable */
// @ts-ignore
import icon from 'vue-svgicon'
icon.register({
  'profile-icon': {
    width: 29,
    height: 24,
    viewBox: '0 0 29 24',
    data: '<path pid="0" d="M21.6 8.285c0 1.985-.863 4.539-2.304 6.597-1.456 2.08-3.315 3.403-5.182 3.403-1.866 0-3.725-1.324-5.18-3.403-1.442-2.058-2.305-4.612-2.305-6.597 0-3.855 3.303-7.066 7.485-7.066 4.183 0 7.486 3.21 7.486 7.066zM28 22.22c0-2.921-3.88-5.399-9.257-6.268M1 22.219c0-2.779 3.513-5.156 8.486-6.13" _stroke="#2540BB" stroke-width="2" stroke-linecap="round"/>'
  }
})
